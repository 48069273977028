import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';
import './Header.css'; // Import the custom CSS file

const Header = () => {
  return (
    <Navbar className="header-navbar">
      <Container className="header-container">
        {/* Navbar links */}
        <Nav className="header-nav">
          <Nav.Link href="./" className="header-link">Home</Nav.Link>
          <Nav.Link href="./contactus.html" className="header-link">Contact Us</Nav.Link>
        </Nav>

        {/* Social Media Icons */}
        <Nav>
        <Nav.Link href="https://twitter.com/ChatGPTapp" className="header-icon" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </Nav.Link>
        <Nav.Link href="https://facebook.com/ChatGPTapp" className="header-icon" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </Nav.Link>
      </Nav>
      </Container>
    </Navbar>
  );
};

export default Header;
