import React, { useState } from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import './FAQComponent.css';

// Custom button for better accessibility and control
function CustomToggle({ children, eventKey, toggleAccordion }) {
  const handleClick = () => {
    toggleAccordion(eventKey);  // Directly invoke toggleAccordion here
  };

  return (
    <Button
      variant="link"
      onClick={handleClick}
      className="w-100 text-start px-4 py-3 d-flex justify-content-between align-items-center text-decoration-none"
    >
      {children}
    </Button>
  );
}

function FAQComponent({ faqs }) {
  const [activeKey, setActiveKey] = useState(null);

  const toggleAccordion = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className="faq-container">
      {/* Loop over the FAQ items and display them in 2-column layout */}
      {faqs.map((faq, index) => (
        <div key={index} className="faq-column">
          <Accordion activeKey={activeKey} onSelect={toggleAccordion}>
            <Card className="bg-dark text-light rounded-3 overflow-hidden mb-3 ">
              <Card.Header className="faq-header p-0">
                <CustomToggle eventKey={String(index)} toggleAccordion={toggleAccordion}>
                  <span className="faq-header">{faq.question}</span>
                  <span className={`transform transition-transform ${activeKey === String(index) ? 'rotate' : ''}`}>
                    <i className="fas fa-plus"></i>
                  </span>
                </CustomToggle>
              </Card.Header>
              <CSSTransition
                in={activeKey === String(index)}
                timeout={300}
                classNames="fade"
                unmountOnExit
              >
                <Accordion.Collapse eventKey={String(index)}>
                  <Card.Body className="faq-body faq-body open"> {faq.answer}</Card.Body>
                </Accordion.Collapse>
              </CSSTransition>
            </Card>
          </Accordion>
        </div>
      ))}
    </div>
  );
}

export default FAQComponent;
