import React, { useState, useRef } from 'react';
import {
  Form,
  Button,
  Spinner,
  Container,
  Row,
  Col,
  Alert,
  Badge,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './App.css'; // Import custom CSS for additional styles

function App() {
  const [text, setText] = useState('');
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState([]);
  const [error, setError] = useState(null);
  const [maxTokens, setMaxTokens] = useState(5500); // Default max tokens
  const lastCopiedRef = useRef(null); // Ref to track the last copied batch
   // State to track which batch was copied and to change the button text
  const [copiedIndex, setCopiedIndex] = useState(null);

  // The API URL is now a constant in the code
  const apiUrl = process.env.REACT_APP_API_URL || 'https://gptchunker.com/api/chunk';

  // Handle input changes
  const handleTextInputChange = (event) => {
    setText(event.target.value);
  };

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleMaxTokensChange = (event) => {
    setMaxTokens(parseInt(event.target.value, 10));
  };

  // Submit form data
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);  // Reset error state before submission
    setOutput([]);   // Reset output state before submission
  
    try {
      let response;
  
      // If there is text input (no file)
      if (text && !file) {
        // Send JSON for text-based requests
        response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            text: text,
            max_tokens: maxTokens,
          }),
        });
      } else if (file) {
        // If there is a file, use FormData
        const formData = new FormData();
        formData.append('file', file);
        formData.append('max_tokens', maxTokens);
  
        response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
        });
      } else {
        // No text or file, throw an error
        throw new Error('Please enter text or upload a file.');
      }
  
      // Handle response
      if (response.ok) {
        const data = await response.json();
        setOutput(data.batches); // Set output data
      } else {
        // Attempt to parse the error response as JSON
        try {
          const errorData = await response.json();
          setError(errorData.error ? errorData.error.message : 'Error processing text.');
        } catch (jsonError) {
          // If the response is not JSON, set a generic error message
          setError('Server responded with an error.');
        }
      }
    } catch (error) {
      // If an error occurs during the request, use the error message
      setError(error.message); // Ensure error.message is a string
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  const handleReset = () => {
    handleScrollToTop();
    setText('');
    //setFile(null);
    setLoading(false);
    setOutput([]);
    setError(null);
    setMaxTokens(5500); // Reset max tokens to default
  };
  
  // Function to handle the copy action
  const handleCopy = (batchIndex) => {
    // Remove the 'last-copied' class from the previously copied batch
    if (lastCopiedRef.current) {
      lastCopiedRef.current.classList.remove('last-copied');
    }

    // Add the 'last-copied' class to the current batch
    const copiedBatch = document.getElementById(`batch-${batchIndex}`);
    copiedBatch.classList.add('last-copied');
    lastCopiedRef.current = copiedBatch;

    // Set the copied index to show "Copied!" on the button
    setCopiedIndex(batchIndex);

    // Reset button text to "Copy" after 2 seconds
    setTimeout(() => {
      setCopiedIndex(null);
    }, 3000);
  };

  const downloadBatches = (format) => {
    const delimiter = format === 'csv' ? ',' : '\n';
    const fileExtension = format === 'csv' ? '.csv' : '.txt';

    const combinedText = output.map((batch) => batch.text).join(delimiter);

    const blob = new Blob([combinedText], { type: `text/${format}` });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `processed_text${fileExtension}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Function to scroll to the top of the page smoothly
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 30,
      left: 30,
      behavior: 'smooth', // Smooth scrolling effect
    });
  };

  return (
    <Container>
      <h1 className="text-center my-4">GPT CHUNKER 🤖</h1>
      <h4>
        GPTChunker provides This online tool divides or split a large input text into smaller chunks based on the
        specified token amount, and prompts ChatGPT accordingly plus output into txt and csv formats.
      </h4>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <div className="fancy-input-group">
              <Form.Group controlId="textInput">
                <Form.Label>Enter Text:</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={10}
                  value={text}
                  onChange={handleTextInputChange}
                  placeholder="Enter your text here or upload a file below..."
                />
              </Form.Group>
            </div>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group className="fancy-input-group" controlId="fileUpload">
              <Form.Label>Upload File (.txt, .docx):</Form.Label>
              <Form.Control type="file" onChange={handleFileUpload} />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="fancy-input-group" controlId="maxTokens">
              <Form.Label>Max Tokens per Prompt (5000 is default for 4o mini Model):</Form.Label>
              <Form.Control
                type="range"
                min="100"
                max="60000"
                step="100"
                value={maxTokens}
                onChange={handleMaxTokensChange}
              />
              <Badge bg="secondary">{maxTokens} Tokens</Badge>
            </Form.Group>
          </Col>
        </Row>

        {error && (
  <Alert variant="danger">
    {typeof error === 'string' ? error : 'An unexpected error occurred'}
  </Alert>
)}

        <Button
          variant="primary"
          type="submit"
          disabled={loading}
          className="button button-primary"
        >
          {loading ? (
            <>
              <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
              <span className="ms-2">Processing...</span>
            </>
          ) : (
            'Process Text'
          )}
    </Button>
        <Button
          variant="secondary"
          onClick={handleReset}
          disabled={loading}
          className="button button-secondary"
        >
          Reset
        </Button>
      </Form>

      {output.length > 0 && (
        <div className="mt-4">
          <h2>Download Output :</h2>
          <div>
            <Button
              variant="success"
              onClick={() => downloadBatches('txt')}
              className="button button-success"
            >
              Download All (.txt)
            </Button>
            <Button
              variant="success"
              onClick={() => downloadBatches('csv')}
              className="button button-success"
            >
              Download All (.csv)
            </Button>
          </div>
          <h2>Copy Prompts :</h2>
          {output.map((batch, index) => (
            <div key={index} className="mb-3">
              <h3>
                {batch.id}{' '}
                <Badge bg="secondary">{batch.tokenCount} tokens</Badge>
              </h3>
              <div className="textarea-container" id={`batch-${index}`}>
                <Form.Control
                  as="textarea"
                  rows={5}
                  value={batch.text}
                  readOnly
                />
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-batch-${index}`}>
                      Copy to clipboard
                    </Tooltip>
                  }
                >
                  <span className="copy-button ">
                    <CopyToClipboard
                      text={batch.text}
                      onCopy={() => handleCopy(index)}
                    >
                      <Button variant="success">{copiedIndex === index ? 'Copied!' : 'Copy'}</Button>
                    </CopyToClipboard>
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          ))}
          <Button
            variant="Warning-reset"
            onClick={handleReset}
            disabled={loading}
          >
           <h4>Reset and Clear all</h4> 
          </Button>
        </div>        
      )}
    </Container>
  );
}

export default App;