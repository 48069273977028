import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Header from './components/Header';
import App from './App';
import reportWebVitals from './reportWebVitals';
import FAQComponent from './components/FAQComponent';
import Footer from './components/Footer';

const faqs = [
  { 
    question: 'What does this tool do?', 
    answer: 'This tool helps you split large pieces of text into smaller chunks that can be processed by AI models like ChatGPT. Since ChatGPT has an input token limit (which varies depending on the model), this tool allows you to break down long content into manageable sections, enabling you to process it efficiently without exceeding token limits.' 
  },
  { 
    question: 'Does ChatGPT have a token limit?', 
    answer: 'Yes, ChatGPT models have a maximum token limit for each request, which includes both input and output tokens. The exact token limit varies by model, for example, GPT-3.5 has a limit of around 4,096 tokens, while GPT-4 can handle up to 8,192 or even 32,768 tokens depending on the variant. If your input text exceeds the token limit, it may be truncated or cause errors.' 
  },
  { 
    question: 'How does ChatGPT Chunker work?', 
    answer: 'The ChatGPT Chunker tool divides your text into smaller segments based on a specified token count. Each chunk is processed individually, allowing you to handle larger texts that might otherwise exceed the model’s token limit. This process ensures that your input stays within the acceptable token range for a given request.' 
  },
  { 
    question: 'What are tokens and how do they work in ChatGPT?', 
    answer: 'In ChatGPT, tokens are chunks of text that the model processes. A token can be as short as a single character or as long as a word. For example, the word "hello" is one token, but a longer word like "unbelievably" could be split into multiple tokens. ChatGPT models process both input and output as tokens, and each model has a maximum number of tokens it can handle in a single request, including both the input text and the generated response.' 
  },
  { 
    question: 'How can I estimate the token usage of my text?', 
    answer: 'You can estimate token usage by using a token calculator or by testing with smaller chunks of text. As a rough guide, one token is approximately 4 characters of English text, which equals about three-fourths of a word (so, 100 tokens is roughly equivalent to 75 words). For more accurate estimation, you can use tools provided by OpenAI or other token calculators available online.' 
  },
  { 
    question: 'What happens if my text exceeds the token limit?', 
    answer: 'If your input text exceeds the model’s token limit, it may be truncated, meaning the text will be cut off at the token limit, potentially leading to incomplete processing. To prevent this, you can use a chunking tool to break the text into smaller parts that fit within the model’s limits.' 
  },
  { 
    question: 'Can I process larger texts using ChatGPT without hitting token limits?', 
    answer: 'Yes, by using tools like the ChatGPT Chunker, you can split large texts into smaller, manageable sections that can be processed one at a time. Additionally, you can look for models with larger token limits, like GPT-4 with the 32k variant, which allows for processing larger contexts.' 
  },
  { 
    question: 'What are alternative models with larger token limits?', 
    answer: 'Some models offer larger token limits than the typical ChatGPT versions. For instance, OpenAI’s GPT-4 can handle up to 32,768 tokens in its extended context window. Other models like Google’s Gemini 1.5 Pro also offer significant context windows, and you may explore these depending on your specific needs. You can also find free models like Gemini 1.5 Pro at platforms like AI Studio (https://aistudio.google.com/), which offers up to 2,000,000 tokens in some cases.' 
  },
  { 
    question: 'Are there any limits on the number of requests I can make to ChatGPT?', 
    answer: 'Yes, ChatGPT has rate limits depending on your subscription or API usage. For free-tier users, there may be restrictions on the number of requests or tokens you can use per month. ChatGPT Plus or higher-tier API users typically have higher usage limits and faster response times.' 
  },
  { 
    question: 'Can I customize how the text is split into chunks?', 
    answer: 'Yes, many chunking tools (including this one) allow you to set the token limit for each chunk based on your needs. For example, you can specify how many tokens each chunk should contain, allowing you to fine-tune how text is divided. It’s important to balance chunk size to avoid exceeding the model\'s maximum token limit and to ensure that each chunk makes sense in terms of context and coherence.' 
  },
  { 
    question: 'What happens if I process a chunk of text out of order?', 
    answer: 'Processing chunks out of order might disrupt the context and flow of the conversation or content. While AI models like ChatGPT are capable of handling some disjointed inputs, keeping the chunks in logical sequence ensures better understanding and more accurate responses. Always try to maintain context and coherence across chunks when splitting large texts.' 
  },
  { 
    question: 'Are there any other tools available for managing token limits?', 
    answer: 'Yes, there are several third-party tools and libraries designed to help with token management. Some of these include OpenAI’s Tokenizer API, which helps you estimate the token usage of your text, and other chunking tools like GPT-3/4 token limit calculators. These tools can assist in managing input sizes and ensuring that your requests stay within the model’s limits.' 
  }
];



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <App />
    <FAQComponent faqs={faqs}/>
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
